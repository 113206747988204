<template>
  <div>
    <h2 class="mb-4 text-2xl font-sansbold">
      {{ $t('homepage.notifications') }}
    </h2>
    <div class="px-4 py-6 border rounded border-secondary-700">
      <span v-if="notifications.length === 0" class="block mb-8">
        {{ $t('homepage.noNotifications') }}
      </span>
      <div v-else class="mb-8">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="flex pt-3 pb-1 border-b border-gray-800"
        >
          <div class="w-[110px] inline-block">
            {{ getStartDate(notification) }}
          </div>
          <button
            class="flex-1 text-left text-secondary-600"
            @click="setCurrentNotification(notification)"
          >
            {{ notification.title }}
          </button>
        </div>
      </div>
      <button
        class="btn btn--primary"
        :disabled="notifications.length === 0"
        @click="goToAllNotifications"
      >
        {{ $t('homepage.showAllNotifications') }}
      </button>
    </div>
    <notification-modal
      v-if="currentNotification"
      :is-open="currentNotification !== null"
      :notification="currentNotification"
      @close="setCurrentNotification(null)"
    />
  </div>
</template>

<script>
import { formatDate } from '@/helpers/DateFormatters';
import NotificationModal from '@/components/user/NotificationModal.vue';
import notificationMixin from '@/components/user/notificationMixin';

export default {
  components: {
    NotificationModal,
  },
  mixins: [notificationMixin],
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getStartDate(notification) {
      return formatDate(notification.startDate);
    },
    goToAllNotifications() {
      this.$router.push({ name: 'notifications' });
    },
  },
};
</script>
