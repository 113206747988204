import { userApi } from './api';

const getNotifications = async (ordering) => {
  let url = 'notification/';
  if (ordering) {
    url += `?ordering=${ordering}`;
  }
  const response = await userApi.get(url);
  return response.data;
};

export { getNotifications };
