<template>
  <div>
    <h2 class="mb-4 text-2xl font-sansbold">
      {{ $t('homepage.favorites') }}
    </h2>
    <div class="px-4 py-6 border rounded border-secondary-700">
      <h3 class="mb-2 text-lg font-sansbold">
        {{ $t('homepage.lastSavedFavorites') }}
      </h3>
      <span v-if="favorites.length === 0" class="block mb-8">
        {{ $t('homepage.noFavorites') }}
      </span>
      <div v-else class="mb-8">
        <router-link
          v-for="favorite in favorites"
          :key="`favorite-${favorite.id}`"
          :to="getRoute(favorite)"
          class="block mb-2"
        >
          {{ getText(favorite) }}
        </router-link>
      </div>
      <button
        class="btn btn--primary"
        :disabled="favorites.length === 0"
        @click="goToFavorites"
      >
        {{ $t('homepage.showAllFavorites') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    favorites: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getRoute(favorite) {
      if (favorite.document) {
        return {
          name: 'documentDetail',
          params: { id: favorite.document.id },
        };
      }
      return {
        name: 'documentDetail',
        params: {
          id: favorite.lexBlock.document.id,
          blockId: favorite.lexBlock.id,
        },
      };
    },
    getText(favorite) {
      return favorite.document?.title || favorite.lexBlock.text;
    },
    goToFavorites() {
      this.$router.push({ name: 'favorites' });
    },
  },
};
</script>
