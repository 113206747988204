<template>
  <modal :is-open="isOpen" class-modal="frontend" @closeModal="closeModal">
    <template #header>
      <span class="block mb-8">
        {{ startDate }} - {{ notification.category.name }}
      </span>
      <h3 class="mr-4 text-4xl leading-snug">{{ notification.title }}</h3>
    </template>
    <div v-html="notification.body" />
    <template #footer>
      <div class="flex justify-end">
        <button class="btn btn--primary" @click="closeModal">
          {{ $t('notificationsView.closeNotification') }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import { formatDate } from '@/helpers/DateFormatters';

export default {
  name: 'UploadForm',
  components: {
    Modal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    notification: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    startDate() {
      return formatDate(this.notification.startDate);
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
