<template>
  <document-layout v-if="isLoggedIn" class="frontend">
    <homepage />
  </document-layout>
  <default-layout v-else class="frontend">
    <div class="my-20 bg-white max-w-[1360px] pt-10 pb-14 px-20 mx-10 4xl:mx-0">
      <div class="grid grid-cols-3">
        <div class="col-span-2 mr-28">
          <h1
            class="mb-10 text-4xl leading-tight whitespace-pre-line  font-sansbold"
          >
            {{ $t('homepage.title') }}
          </h1>
          <p class="text-base">{{ $t('homepage.aboutAsaWebLex') }}</p>
          <button class="mt-8 btn btn--primary" @click="goToLogin">
            {{ $t('homepage.login') }}
          </button>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import Homepage from '@/components/user/Homepage.vue';

export default {
  name: 'Dashboard',
  components: {
    Homepage,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['Auth/isLoggedIn'];
    },
  },
  mounted() {
    document.body.classList.add('frontend');
    document.body.classList.remove('backend');
  },
  methods: {
    goToLogin() {
      this.$router.push('login');
    },
  },
};
</script>
