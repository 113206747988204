<template>
  <div class="px-10 py-12 4xl:px-0 responsive-container">
    <div class="px-20 pt-10 bg-white shadow-md pb-14">
      <div class="col-span-2 max-w-[60%]">
        <h1
          class="mb-10 text-4xl leading-tight whitespace-pre-line font-sansbold"
        >
          {{ $t('homepage.title') }}
        </h1>
        <div
          v-if="maintenanceNotification"
          class="
            bg-secondary-800 bg-opacity-[15%]
            w-full
            p-3
            rounded
            flex
            mb-10
          "
        >
          <svg-icon name="attention" class="w-5 h-5 text-secondary-600" />
          <div class="flex-1 ml-4">
            <span class="font-sansbold">
              {{ maintenanceNotification.title }}
            </span>
            <span class="block" v-html="maintenanceNotification.body"></span>
          </div>
        </div>
      </div>
      <div class="flex">
        <homepage-notification-box
          class="w-[60%] mr-24"
          :notifications="asaNotifications"
        />
        <homepage-favorite-box class="flex-1" :favorites="favorites" />
      </div>
    </div>
  </div>
</template>

<script>
import { getNotifications } from '@/services/notification';
import HomepageNotificationBox from '@/components/user/HomepageNotificationBox.vue';
import HomepageFavoriteBox from '@/components/user/HomepageFavoriteBox.vue';
import EditorEventBus from '@/helpers/EditorEventBus';

export default {
  components: {
    HomepageNotificationBox,
    HomepageFavoriteBox,
  },
  data() {
    return {
      favorites: [],
      notifications: [],
    };
  },
  computed: {
    maintenanceNotification() {
      // Its possible to have more than one maintenance notification but we just ignore that here. Only one is shown
      return this.notifications.find(
        (notification) => notification.category.slug === 'maintenance'
      );
    },
    asaNotifications() {
      return this.notifications
        .filter((notification) => notification.category.slug !== 'maintenance')
        .splice(0, 6);
    },
  },
  async mounted() {
    await this.loadDashboard();
    EditorEventBus.$on('reloadDashboard', this.loadDashboard);
  },
  beforeDestroy() {
    EditorEventBus.$off('reloadDashboard', this.loadDashboard);
  },
  methods: {
    async loadDashboard() {
      this.notifications = await getNotifications();
      await this.$store.dispatch('Favorite/loadFavorites');
      this.favorites = this.$store.state.Favorite.favorites[this.$i18n.locale]
        .reverse()
        .splice(0, 6);
    },
  },
};
</script>

<style></style>

<style lang="postcss" scoped>
/deep/ p {
  @apply text-base;
}
</style>
